<template>
  <section class="quick-idea outlined">
      <h2 class="title"
          :data-color="!colorRight ? colorLeft : undefined"
          :data-color-left="colorRight ? colorLeft : undefined"
          :data-color-right="colorRight ?? undefined">
          Quick Idea
      </h2>
      <p class="no-margin">
        Try <span class="underline-color-2">reading an inspiring book</span> while <span class="underline-color-1">hammocking</span>
      </p>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {
      colorLeft: "1",
      colorRight: "2"
    }
  },
})
</script>

<style scoped>
.quick-idea {
  padding: 20px 14px 20px 14px;
  margin: 30px 30px 20px 30px;
  position: relative;
}
.title {
  position: absolute;
  top: -13px;
  left: -6px;
  padding: 0px 4px;
  background-color: var(--color-bg);
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 18px;
  margin: 0;
  padding-left: 28px;
}
.title:before,
.title:after {
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 50%;
  mix-blend-mode: screen;
  position: absolute;
}
.title[data-color-left]:before {
  top: 4px;
  left: 0;
}
.title[data-color-right]:after {
  top: 9px;
  left: 5px;
}
.title[data-color]:before {
  top: 2px;
  left: 3px;
}
.title[data-color="1"]:before,
.title[data-color-left="1"]:before,
.title[data-color-right="1"]:after {
  background-color: var(--color-1);
}
.title[data-color="2"]:before,
.title[data-color-left="2"]:before,
.title[data-color-right="2"]:after {
  background-color: var(--color-2);
}
.title[data-color="3"]:before,
.title[data-color-left="3"]:before,
.title[data-color-right="3"]:after {
  background-color: var(--color-3);
}
.title[data-color="4"]:before,
.title[data-color-left="4"]:before,
.title[data-color-right="4"]:after {
  background-color: var(--color-4);
}
</style>