
import { defineComponent, ref } from 'vue'

import Levels, { DURATION } from '@/components/Visualization/Levels.vue'
import { LevelSet } from '@/models/models'

export default defineComponent({
  components: {
    Levels
  },
  setup() {
    const levels = ref(new LevelSet(1,1,1,1))
    const showHelp = ref(false)
    const showLevels = ref(false)

    setTimeout(() => {
      showLevels.value = true;
      levels.value = new LevelSet(6,6,6,6)
    }, DURATION*0.5)

    setTimeout(() => {
      showHelp.value = true
    }, DURATION*1.6)

    return {
      levels,
      showHelp,
      showLevels
    }
  },
})
