<template>
  <router-link class="undecorated-link log-item-container"
              :to="{ name: 'LogEntry', params: { id: mostRecentEntry.id }}">
    <levels class="levels" v-bind="levels" updateFavicon/>
    <section class="text-align-center">
      <small>
        Most recent entry was {{mostRecentEntryTimeAgo}}
      </small>
    </section>
  </router-link>
  <section id="selectors">
    <a class="button outline" :data-level="levels.physical">Physical</a>
    <a class="button outline" :data-level="levels.spiritual">Spiritual</a>
    <a class="button outline" :data-level="levels.mental">Mental</a>
    <a class="button outline" :data-level="levels.social">Social</a>
  </section>
  <a class="undecorated-link">
    <quick-idea />
  </a>
  <teleport to="#button-bar">
    <router-link class="button text" to="log">Log</router-link>
    <router-link class="add-entry-btn button large" to="add-entry">Add Entry</router-link>
  </teleport>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, ref, watch } from 'vue'
import ago from 's-ago'

import Levels from '@/components/Visualization/Levels.vue'
import QuickIdea from '@/components/QuickIdeas/QuickIdea.vue'
import { Entry, LevelSet } from '@/models/models'

export default defineComponent({
  components: {
    Levels,
    QuickIdea
  },
  props: {
    mostRecentEntry: {
      type: Object as () => Entry,
      required: true
    },
    penultimateEntry: {
      type: Object as () => Entry,
      required: false
    },
    animate: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const toLevels = computed(() => props.mostRecentEntry.levels)
    const levels = ref(props.animate? new LevelSet(5,5,5,5) : toLevels.value)
    const mostRecentEntryTimeAgo = computed(() => ago(props.mostRecentEntry.datetime))

    watch(toLevels, (v: LevelSet) => {
      if (props.animate && !levels.value.equals(v)) {
        levels.value = props.penultimateEntry?.levels ?? levels.value
        nextTick(() => { //This allows it to animate between penultimateEntry and mostRecentEntry
          levels.value = v
        })
      }
      else {
        levels.value = v
      }
    }, {
      immediate: true
    })

    return {
      levels,
      mostRecentEntryTimeAgo
    }
  },
})
</script>

<style scoped>
#selectors {
  margin: 10px 10px;
  min-width: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
#selectors .button {
  flex: 1;
  margin: 5px;
  position: relative;
  padding-left: 48px;
  padding-right: 10px;
  height: 48px;
  border-radius: 24px;
  min-width: 175px;
}
#selectors .button::before {
  color: var(--color-bg);
  text-align: center;
  line-height: 1.9;
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: -1px;
  z-index: 1;
  font-size: 16px;
  letter-spacing: 0;
  content: attr(data-level);
}
#selectors .button:nth-child(1)::before {
  background-color: var(--color-1);
}
#selectors .button:nth-child(2)::before {
  background-color: var(--color-2);
}
#selectors .button:nth-child(3)::before {
  background-color: var(--color-3);
}
#selectors .button:nth-child(4)::before {
  background-color: var(--color-4);
}
</style>