
import { computed, defineComponent, nextTick, ref, watch } from 'vue'
import ago from 's-ago'

import Levels from '@/components/Visualization/Levels.vue'
import QuickIdea from '@/components/QuickIdeas/QuickIdea.vue'
import { Entry, LevelSet } from '@/models/models'

export default defineComponent({
  components: {
    Levels,
    QuickIdea
  },
  props: {
    mostRecentEntry: {
      type: Object as () => Entry,
      required: true
    },
    penultimateEntry: {
      type: Object as () => Entry,
      required: false
    },
    animate: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const toLevels = computed(() => props.mostRecentEntry.levels)
    const levels = ref(props.animate? new LevelSet(5,5,5,5) : toLevels.value)
    const mostRecentEntryTimeAgo = computed(() => ago(props.mostRecentEntry.datetime))

    watch(toLevels, (v: LevelSet) => {
      if (props.animate && !levels.value.equals(v)) {
        levels.value = props.penultimateEntry?.levels ?? levels.value
        nextTick(() => { //This allows it to animate between penultimateEntry and mostRecentEntry
          levels.value = v
        })
      }
      else {
        levels.value = v
      }
    }, {
      immediate: true
    })

    return {
      levels,
      mostRecentEntryTimeAgo
    }
  },
})
