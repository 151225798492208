
import { defineComponent, onBeforeUnmount } from 'vue'

import StandardHome from '@/components/Home/StandardHome.vue'
import EmptyHome from '@/components/Home/EmptyHome.vue'
import { checkIsHomeToMostRecentEntry, setIsHomeToMostRecentEntry } from '@/utils/main-to-most-recent-entry'
import { StoreService, useServiceInjector } from '@/injection'

const REFRESH_RATE = 30 /* seconds */ * 1000 /* milliseconds */

export default defineComponent({
  components: {
    StandardHome,
    EmptyHome
  },
  setup() {
    const inject = useServiceInjector()

    const store = inject(StoreService)
    
    const mostRecentEntry = store.mostRecentEntrySync
    const penultimateEntry = store.penultimateEntrySync

    const intervalId = setInterval(() => {
      store.refreshMostRecentEntries()
    }, REFRESH_RATE)

    onBeforeUnmount(() => {
      clearInterval(intervalId)
    })

    return {
      penultimateEntry,
      mostRecentEntry,
      animate: !checkIsHomeToMostRecentEntry()
    }
  },
  beforeRouteLeave(to) {
    if (to.name === "LogEntry") {
      //Going to most recent entry's LogEntry page
      setIsHomeToMostRecentEntry()
    }
  }
})
