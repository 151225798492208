<template>
  <section class="two-layer-stack">
    <levels :class="[showLevels? '' : 'hidden', showHelp ? 'background' : '', 'levels']" v-bind="levels"/>
    <div :class="['text-align-center', 'foreground', showHelp? '' : 'hidden']">
      Tap "Add&nbsp;Entry" to get started
    </div>
  </section>
  <teleport to="#button-bar">
    <span><!--This makes the add entry button right-aligned--></span>
    <router-link class="button large" to="add-entry">Add Entry</router-link>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import Levels, { DURATION } from '@/components/Visualization/Levels.vue'
import { LevelSet } from '@/models/models'

export default defineComponent({
  components: {
    Levels
  },
  setup() {
    const levels = ref(new LevelSet(1,1,1,1))
    const showHelp = ref(false)
    const showLevels = ref(false)

    setTimeout(() => {
      showLevels.value = true;
      levels.value = new LevelSet(6,6,6,6)
    }, DURATION*0.5)

    setTimeout(() => {
      showHelp.value = true
    }, DURATION*1.6)

    return {
      levels,
      showHelp,
      showLevels
    }
  },
})
</script>

<style scoped>
.foreground {
  font-size: 1.6rem;
  opacity: 1;
}
.hidden {
  opacity: 0;
}
.levels,
.foreground {
  transition: opacity 0.5s ease;
}
</style>