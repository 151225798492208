
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {
      colorLeft: "1",
      colorRight: "2"
    }
  },
})
