<template>
  <div>
    <teleport to="#title-bar">
      <h1>Four Circles</h1>
      <router-link class="button text" to="menu">Menu</router-link>
    </teleport>
    <main class="content two-columns wide">
      <standard-home v-if="mostRecentEntry != null"
                    :mostRecentEntry="mostRecentEntry"
                    :penultimateEntry="penultimateEntry ?? undefined"
                    :animate="animate"/>
      <empty-home v-else/>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount } from 'vue'

import StandardHome from '@/components/Home/StandardHome.vue'
import EmptyHome from '@/components/Home/EmptyHome.vue'
import { checkIsHomeToMostRecentEntry, setIsHomeToMostRecentEntry } from '@/utils/main-to-most-recent-entry'
import { StoreService, useServiceInjector } from '@/injection'

const REFRESH_RATE = 30 /* seconds */ * 1000 /* milliseconds */

export default defineComponent({
  components: {
    StandardHome,
    EmptyHome
  },
  setup() {
    const inject = useServiceInjector()

    const store = inject(StoreService)
    
    const mostRecentEntry = store.mostRecentEntrySync
    const penultimateEntry = store.penultimateEntrySync

    const intervalId = setInterval(() => {
      store.refreshMostRecentEntries()
    }, REFRESH_RATE)

    onBeforeUnmount(() => {
      clearInterval(intervalId)
    })

    return {
      penultimateEntry,
      mostRecentEntry,
      animate: !checkIsHomeToMostRecentEntry()
    }
  },
  beforeRouteLeave(to) {
    if (to.name === "LogEntry") {
      //Going to most recent entry's LogEntry page
      setIsHomeToMostRecentEntry()
    }
  }
})
</script>

<style scoped>
::v-deep(.levels) {
  margin: 20px auto;
  height: var(--base-viz-size);
  width: var(--base-viz-size);
  max-height: var(--base-viz-max-size);
  max-width: var(--base-viz-max-size);
}
::v-deep(#button-add-entry) {
  max-width: 18rem;
  margin: 0 auto;
}
</style>